import React, { useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import FileItem from "../FileUploader/FileItem";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect } from "react";
import Loading from "../Loading";
import NetworkError from "../Modals/NetworkError";
import Swal from 'sweetalert2'
function ProductsPage() {
  const [showError, setShowError] =useState(false)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [image, setImage] = useState([]);
  const [imageData, setImageData] = useState([]);
  const removeImage = (filename, index) => {
    setImage(null);
    setImageData(null);
  };

  const [databaseImage, setDatabaseImage] = useState([]);
  const removeDatabaseImage = (index) => {
    setDatabaseImage(databaseImage.filter((file, i) => i !== index));
  };

  const fetchImage = async () => {
    await axios
      .get("https://api.camp-coding.site" + "/pages/getall/products")
      .then((Response) => {
        setDatabaseImage([Response.data[0].path_image]);
      });
  };

  useEffect(() => {
    fetchImage();
  }, []);
  useEffect(() => {}, [databaseImage]);

  const updateImage = async () => {
    if (image.length != 0) {
      setError(null);
      setLoading(true);
      const formData = new FormData();
      formData.append("file", image[0], image[0]?.name);
      axios
        .post(
          `${"https://api.camp-coding.site"}/pages/upload/product-page/image`,
          formData
        )
        .then((res) => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Your changes had been saved',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch((err) => {
          console.log(err);
          if (err.code==="ERR_NETWORK"){
            setLoading(false)
            setShowError(true)
            setTimeout(() => {
              setShowError(false)
            }, 3000);
          }
        });
    } else if (
      image.length === 0 &&
      databaseImage.length === 0
    ) {
      setError("Please add an image first.");
    }
  };

  return (
    <div>
      <div className="flex">
        <div>
          <div className="text-[#000] font-[700] text-[14px]">1.</div>
        </div>
        <div className="ml-[6%]">
          <p className="text-[#000] font-[700] text-[14px]">Products Image</p>
          <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
            Select an for the products page ( JPEG or PNG ):
            <span className="text-[red] font-[700]">*</span>
          </p>
          <div className=" w-[130px] h-[30px] mt-[10px]">
            <FileUpload
              setDatabaseFile={setDatabaseImage}
              files={image}
              setFiles={setImage}
              setFileData={setImageData}
              removeFile={removeImage}
            />
          </div>
          <div className>
            <FileItem
              removeDatabaseFile={removeDatabaseImage}
              databaseFile={databaseImage}
              fileData={imageData}
              file={image?.[0]}
              removeFile={removeImage}
              index="0"
            />
          </div>
        </div>
      </div>
      {error ? (
        <p className="text-[14px] ml-4 font-[400] mt-2 text-[red]">
          <FontAwesomeIcon icon={faCircleExclamation} /> {error}
        </p>
      ) : null}
      {loading && <Loading />}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="absolute flex pb-[10px] right-[80px] bottom-[0px]">
        <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
          Cancel
        </div>
        <div
          onClick={updateImage}
          className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
        >
          Save
        </div>
      </div>
    </div>
  );
}

export default ProductsPage;
