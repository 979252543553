import React from "react";
import SideBar from "../components/SideBar";

import { faUser, faCaretDown, faEye } from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../global/columns/orderColumns-2";
import { Link } from "react-router-dom";
import StatusSelector from "../components/StatusSelector";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
import Breadcrumbs from "../components/Breadcrumbs";
import UserIcon from "../components/UserIcon";
import SearchBar from "../components/SearchBar";
import { selectSearch } from "../redux/slices/itemSlice";
import { setSearch } from "../redux/slices/itemSlice";
import Footer from "../components/Footer";
function Orders() {
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;

  const [orders, setOrders] = useState([]);
  const fetchOrders = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/orders/findAll`
    );
    const data = response.data;
    if (data.length !== orders.length) {
      setOrders(data);
    }
  };

  var data = [];
  useEffect(() => {
    fetchOrders();
  }, []);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(orders);
  }, [orders]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        orders.filter((order) => {
          return order.receiver_name.includes(searchInput);
        })
      );
    } else {
      setFilteredData(orders);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    const order = filteredData[i];
    data = [
      ...data,
      {
        id: (
          <div className="text-[14px]  font-[300]  text-[#0388CC]">
            {order.id}{" "}
          </div>
        ),
        phone: (
          <div className="text-[14px] font-[600] underline text-[#0F3F62]">
            {order.receiver_phone}
          </div>
        ),
        email: (
          <div className="text-[14px] font-[300] underline text-[#0388CC]">
            {order.receiver_name}
          </div>
        ),
        products: <div className="font-[400]">{order.order_items.length}</div>,
        adress: <div className="font-[400]">{order.shipping_add} </div>,
        time: (
          <div className="font-[400]">{order.CreatedAt.slice(11, 19)} </div>
        ),
        date: <div className="font-[400]">{order.CreatedAt.slice(0, 10)}</div>,
        total: (
          <div className="text-[14px] font-[600] text-[#000]">
            {order.total}
          </div>
        ),
        status: (
          <div className="z-50 overflow-visible l-[35px] px-1 font-[400] text-[white] py-[2px] min-w-[100px] rounded-md">
            <StatusSelector
              disabled={!["Sales", "Admin"].includes(adminRole)}
              key={order.order_status}
              orderId={order.id}
              status={order.order_status}
            />
          </div>
        ),
        view: (
          <Link
            to={
              ["Sales", "Admin"].includes(adminRole)
                ? `/order-details?id=${order.id}`
                : "#"
            }
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
      },
    ];
  }

  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>
        <div className="w-full flex flex-col ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <Breadcrumbs
              breads={[
                {
                  title: "Orders",
                  link: "/orders",
                },
              ]}
            />
            <div className="flex items-center h-8 w-[30%]">
              <SearchBar placeholder="Search by client's e-mail"/>
              <UserIcon />
            </div>
          </div>
          <div className="bg-[white] grow mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
            <div className=" p-3 w-full">
              <ReactDataGrid
                idProperty="id"
                columns={columns}
                showCellBorders={showCellBorders}
                showZebraRows={showZebraRows}
                style={gridStyle}
                dataSource={data}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Orders;
