/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

function CouponStatus({status, couponId, disabled}) {
  const [active, setActive] = useState(status)
  const changeState = () => {
    if (!disabled) {
      active
        ? axios
            .get(
              `${"https://api.camp-coding.site"}/coupons/updatestate/${couponId}/desactive`
            )
            .then(setActive(false))
        : axios
            .get(
              `${"https://api.camp-coding.site"}/coupons/updatestate/${couponId}/active`
            )
            .then(setActive(true));
    }
  };

  return (
    <div
    onClick={changeState}
    className="w-[100%] cursor-pointer font-[400] flex items-center text-[13px] h-[35px] mr-2 rounded "
  >
    <div className={active? "text-[green] mr-2" : "text-[red] mr-2"}>{active ? "Active" :"Not Active"}</div>{" "}
    <div>
      <FontAwesomeIcon icon={faSort} />
    </div>
  </div>
  )
}


export default CouponStatus;