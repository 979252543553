import React, { useEffect, useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import SettingsTab from "../SettingsTab";
import PolicyForm from "../PolicyForm";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/settingsTabs.module.css";
import Images from "../ProductSettings/Images";
import PDF from "../ProductSettings/PDF";
import Video from "../ProductSettings/Video";
import SKU from "../ProductSettings/SKU";
import Manufacturers from "../ProductSettings/Manufacturers";
import axios from "axios";
import { upload } from "@testing-library/user-event/dist/upload";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import NetworkError from "./NetworkError";
import Swal from "sweetalert2";
import Loading from "../Loading";
function NewProduct({ setShowModal, products, setProducts }) {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  //GETTING ALL THE CATEGORIES
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/categories/findall`
    );
    setCategories(response.data);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const [files, setFiles] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [sku, setSku] = useState([]);
  const [skuCode, setSkuCode] = useState("");
  const [manufacturers, setManufacturers] = useState("");

  const [descAr, setDescAr] = useState("");

  const [descEn, setDescEn] = useState("");

  const [values, setValues] = useState({
    nameEn: "",
    nameAr: "",
    category: "",
    price: "",
    points: "",
    reviews: null,
    quantity: "",
  });
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [imageUrls, setImageUrls] = useState([]);
  const uploadImages = async () => {
    try {
      for (let i = 0; i < files.length; i++) {
        await uploadImage(files[i]);
      }
      setImageUrls(images);
    } catch (err) {
      console.log(err);
    }
  };

  const submitProduct = (e) => {
    e.preventDefault();
    if (
      values.nameEn !== "" &&
      values.nameAr !== "" &&
      values.quantity !== "" &&
      values.points !== "" &&
      values.price !== "" &&
      values.reviews &&
      skuCode !== "" &&
      values.category !== "" &&
      files.length !== 0 &&
      sku.length !== 0
    ) {
      setError(null);
      setLoading(true);
      uploadImages();
    } else {
      setError("Please fill all the required fields.");
    }
  };

  useEffect(() => {
    if (imageUrls !== []) {
      uploadPdf();
    }
  }, [imageUrls]);

  const [pdfUrl, setPdfUrl] = useState([]);
  const uploadPdf = async () => {
    if (pdf.length > 0) {
      const formData = new FormData();
      formData.append("file", pdf[0], pdf[0]?.name);
      await axios
        .post(
          `${"https://api.camp-coding.site"}/products/upload/product-pdf`,
          formData
        )
        .then((pdfResponse) => {
          setPdfUrl([pdfResponse.data.imagePath]);
        })
        .catch((err) => {
          // inform the user
          console.error(err);
        });
    } else {
      setPdfUrl([]);
    }
  };

  useEffect(() => {
    completeUpload();
  }, [pdfUrl]);

  const completeUpload = async () => {
    const formData2 = new FormData();
    formData2.append("file", sku[0], sku[0]?.name);
    axios
      .post(
        `${"https://api.camp-coding.site"}/products/upload/product-sku`,
        formData2
      )
      .then((skuResponse) => {
        axios
          .post(`${"https://api.camp-coding.site"}/products/create`, {
            name_en: values.nameEn,
            name_ar: values.nameAr,
            description_en: descEn,
            description_ar: descAr,
            category_id: parseInt(values.category),
            image_urls: imageUrls,
            sku: skuResponse.data.imagePath,
            sku_code: skuCode,
            stock: parseInt(values.quantity),
            video_url: videoUrl,
            points: parseInt(values.points),
            price: parseInt(values.price),
            fees_tax: false,
            product_review: values.reviews,
            product_pdf: pdfUrl[0],
            manufacter: manufacturers,
          })
          .then(function (response) {
            setLoading(false);
            setProducts([...products, response.data]);
            setShowModal(false);
            Swal.fire({
              icon: "success",
              title: "Your changes had been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(function (error) {
            console.log(error.response.data.message);
          });
      })
      .catch((err) => {
        // inform the user
        console.error(err);
      });
  };

  //File upload
  var images = [];
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file, file?.name);
    await axios
      .post(
        `${"https://api.camp-coding.site"}/products/upload/product-image`,
        formData
      )
      .then((imgResponse) => {
        const imageUrl = imgResponse.data.imagePath;
        images = [...images, imageUrl];
      })
      .catch((err) => {
        if (err.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <>
      <form onSubmit={submitProduct}>
        <div className="justify-center mt-[-30px] h-[800px] items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative my-6 w-[85%]">
            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between h-[50px]  rounded-t">
                <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                  Add New Product
                </div>
                <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
              </div>
              {/* {body} */}
              <div className="relative h-[600px] p-6 w-full scrollbar-thin h-full scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full ">
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-[45%] mt-2 mr-[20px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="name-en"
                      >
                        Name - En{" "}
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                        placeholder="Enter product name in english"
                        onChange={changeHandler}
                        value={values.nameEn}
                        name="nameEn"
                        type="text"
                        id="name-en"
                      />
                    </div>
                    <div className="w-[45%] mt-2 mr-[20px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="name-ar"
                      >
                        Name - Ar{" "}
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                        placeholder="Enter product name in arabic"
                        onChange={changeHandler}
                        value={values.nameAr}
                        name="nameAr"
                        type="text"
                        id="name-ar"
                      />
                    </div>
                    <div className="w-[45%] mt-4 mr-[20px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="category"
                      >
                        Category{" "}
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <br />
                      <select
                        required
                        value={values.category}
                        name="category"
                        onChange={changeHandler}
                        className="w-full h-[30px] px-1 rounded border-[#c4c8cf] font-[400] text-[14px] text-[#000]"
                      >
                        <option value="">Choose a category</option>
                        {categories.map((category) => (
                          <option value={category.id}>
                            {category.category_translations[1].name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-[45%] mt-4 mr-[20px]">
                      <label className="text-[14px] font-[500]" htmlFor="price">
                        Price <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                        placeholder="Enter product price"
                        onChange={changeHandler}
                        value={values.price}
                        min="0"
                        type="number"
                        name="price"
                        id="price"
                      />
                    </div>
                    <div className="flex flex-wrap mt-4 w-[97.5%]">
                      <RichTextEditor
                        name="Description-EN"
                        initialValue=""
                        getValue={setDescEn}
                      />
                      <RichTextEditor
                        name="Description-AR"
                        initialValue=""
                        getValue={setDescAr}
                      />
                    </div>
                    <div className="w-[45%] mt-2 mr-[20px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="points"
                      >
                        Points <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                        placeholder="Enter product points"
                        onChange={changeHandler}
                        value={values.points}
                        name="points"
                        min="0"
                        type="number"
                        id="points"
                      />
                    </div>
                    <div className="w-[45%] mt-2 mr-[20px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="points"
                      >
                        Quantity{" "}
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[500]"
                        placeholder="Enter the product initial quantity"
                        onChange={changeHandler}
                        value={values.quantity}
                        name="quantity"
                        min="0"
                        type="number"
                        id="quantity"
                      />
                    </div>
                    <div className="w-[45%] mt-8 mr-[20px] flex">
                      <div className="mr-[20px] text-[15px] font-[500] text-[#000]">
                        Select Product Reviews
                      </div>
                      <div>
                        <input
                          required
                          className="mr-[10px] focus:border-none focus:outline-none ml-[30px]"
                          type="radio"
                          name="reviews"
                          onChange={() => {
                            setValues({ ...values, reviews: true });
                          }}
                          value="on"
                          id="on"
                        />
                        <label htmlFor="on" className="text-[14px] font-[500]">
                          On
                        </label>
                        <input
                          required
                          className="mr-[10px] ml-[30px]"
                          type="radio"
                          onChange={() => {
                            setValues({ ...values, reviews: false });
                          }}
                          name="reviews"
                          value="off"
                          id="off"
                        />
                        <label htmlFor="off" className="text-[14px] font-[500]">
                          Off
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <label className="text-[14px] font-[500]">
                      Settings <span className="text-[red] font-[700]">*</span>
                    </label>
                    <div
                      className={`${styles.tabs} flex justify-between w-[91%] rounded-xl`}
                    >
                      <Images
                        files={files}
                        setFiles={setFiles}
                        content="Images"
                        title="Images"
                        value="11"
                      />
                      <PDF
                        pdf={pdf}
                        setPdf={setPdf}
                        content="PDF"
                        title="PDF"
                        value="22"
                      />
                      <Video
                        videoUrl={videoUrl}
                        setVideoUrl={setVideoUrl}
                        content="Video"
                        title="Video"
                        value="33"
                      />
                      <SKU
                        sku={sku}
                        setSku={setSku}
                        skuCode={skuCode}
                        setSkuCode={setSkuCode}
                        content="SKU"
                        title="SKU"
                        value="44"
                      />
                      <Manufacturers
                        manufacturers={manufacturers}
                        setManufacturers={setManufacturers}
                        content="Manufacturers"
                        title="Manufacturers"
                        value="55"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*footer*/}
              {error ? (
                <div>
                  <p className="text-[14px] ml-8 text-[red]">
                    <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                  </p>
                </div>
              ) : null}
              <div className="flex items-center justify-end p-3 rounded-b">
                <div className=" flex pb-[10px] ">
                  <div
                    onClick={() => setShowModal(false)}
                    className="cursor-pointer rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                  >
                    Cancel
                  </div>
                  <input
                    required
                    type="submit"
                    className="cursor-pointer rounded-full cursor-pointer text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                    value="Save"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loading ? <Loading /> : null}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default NewProduct;
