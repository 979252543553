import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/offerColumns";
import {
  faCirclePlus,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NewOffer from "../Modals/NewOffer";
import OfferStatus from "../OfferStatus";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import DeleteWarning from "../Modals/DeleteWarning";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
function Offers() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const [showModal, setShowModal] = React.useState(false);
  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;

  const [offers, setOffers] = useState([]);
  var data = [];
  const fetchOffers = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/offers/alloffers`
    );
    const data = response.data;
    if (data.length !== offers.length) {
      setOffers(data);
    }
  };

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  useEffect(() => {
    fetchOffers();
  }, [offers]);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(offers);
  }, [offers]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        offers.filter((offer) => {
          return offer.name.includes(searchInput);
        })
      );
    } else {
      setFilteredData(offers);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    const offer = filteredData[i];
    data = [
      ...data,
      {
        id: offer.id,
        offerName: offer.name,
        categories: offer.Categories?.map(
          (category) => category.category_translations[1].name + ", "
        ),
        value: offer.percentage,
        creationDate: "offer",
        expirationDate: offer.expiry_date.slice(0, 10),
        status: (
          <OfferStatus
            disabled={!["Sales", "Admin"].includes(adminRole)}
            key={offer.status}
            offerId={offer.id}
            status={offer.status}
          />
        ),
        view: (
          <Link
            to={
              ["Sales", "Admin"].includes(adminRole)
                ? `/offer-details?id=${offer.id}`
                : "/offers?tab=1"
            }
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
            onClick={() => {
              if (["Sales", "Admin"].includes(adminRole)) {
                setItemToDelete({ id: offer.id, index: i });
                setShowWarningModal(true);
              }
            }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  return (
    <div className="flex flex-col justify-between">
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewOffer
          offers={offers}
          setOffers={setOffers}
          setShowModal={setShowModal}
        />
      ) : null}
      {showWarningModal ? (
        <DeleteWarning
          url="/offers/delete/"
          setShowModal={setShowWarningModal}
          itemToDelete={itemToDelete}
          items={offers}
          setItems={setOffers}
        />
      ) : null}
      <div
        onClick={() =>
          ["Sales", "Admin"].includes(adminRole) && setShowModal(true)
        }
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[0px] ml-[20px] text-[13px]"
      >
        <div className="mr-2">Add new offer </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </div>
  );
}

export default Offers;
