import React, { useState, useEffect } from "react";
import axios from "axios";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Loading from "../Loading";
import NetworkError from "./NetworkError";
import Swal from "sweetalert2";
function NewCoupon({ setShowModal, setCoupons, coupons }) {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    couponCode: "",
    type: "Percentage",
    value: "",
    appliesTo: [],
    expirationDate: "",
  });

  const generateCode = () => {
    var length = 10,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setValues({ ...values, couponCode: "IQ-" + retVal });
  };

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitCoupon = (e) => {
    e.preventDefault();
    if (
      values.couponCode !== "" &&
      values.value !== "" &&
      values.expirationDate !== ""
    ) {
      setError(null);
      setLoading(true);
      axios
        .post(`${"https://api.camp-coding.site"}/coupons/create`, {
          coupon_code: values.couponCode,
          type: values.type,
          value: parseInt(values.value),
          applies_to_arr_products: values.appliesTo,
          expiry_date: values.expirationDate,
        })
        .then(function (response) {
          setLoading(false);
          setCoupons([...coupons, response.data]);
          setShowModal(false);
          Swal.fire({
            icon: "success",
            title: "Your changes had been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(function (error) {
          console.log(error);
          if (error.code === "ERR_NETWORK") {
            setLoading(false);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
          }
        });
    } else {
      setError("Please fill all required fields");
    }
  };

  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/products/allProducts`
    );
    const data = response.data;
    if (data.length !== products.length) {
      setProducts(data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  var optionsArray = [];
  const [options, setOptions] = useState([]);
  useEffect(() => {
    products.map((category) => {
      optionsArray = [
        ...optionsArray,
        {
          value: category.id,
          label: category.product_translations[1].nameProduct,
        },
      ];
    });
    setOptions(optionsArray);
  }, [products]);

  const multiSelectOnChange = (selectedOptions) => {
    var selected = [];
    selectedOptions.map((option) => {
      selected = [...selected, option.value];
    });
    setValues({ ...values, appliesTo: selected });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[85%]">
          <form onSubmit={submitCoupon}>
            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between h-[50px]  rounded-t">
                <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                  Add New Coupon
                </div>
                <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
              </div>
              {/* {body} */}
              <div className="relative p-6 w-full ">
                <div className="w-[95%] text-[14px] font-[500]">
                  <div className="flex text-[14px] font-[500]">
                    <div className="w-[20%] text-[14px] mr-4 font-[500]">
                      <label htmlFor="coupon-code">
                        Coupon Code <span className="text-[red]">*</span>
                      </label>
                    </div>
                    <input
                      required
                      className="w-[60%] h-[35px] mr-2 rounded border-[#0388CC] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                      placeholder="Enter or automatically generate a coupon code"
                      onChange={changeHandler}
                      value={values.couponCode}
                      name="couponCode"
                      type="text"
                      id="coupon-code"
                    />
                    <div
                      onClick={generateCode}
                      className="text-[14px] cursor-pointer justify-center flex items-center px-2 rounded-md w-[140px] text-center font-[400] h-[35px] text-[#fff]  bg-[#0388CC]"
                    >
                      Auto Generate
                    </div>
                  </div>
                  {/* {} */}
                  <div className="flex mt-6 text-[14px] font-[500]">
                    <div className="w-[20%] text-[14px] mr-4 font-[500]">
                      <label htmlFor="type">
                        Type <span className="text-[red]">*</span>
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        values.type === "Percentage"
                          ? setValues({ ...values, type: "Fixed" })
                          : setValues({ ...values, type: "Percentage" });
                      }}
                      className="w-[30%] cursor-pointer font-[400] flex items-center px-3 justify-between text-[14px] h-[35px] mr-2 rounded border-[#0388CC] border-[1px]"
                    >
                      <div>{values.type}</div>{" "}
                      <div>
                        <FontAwesomeIcon icon={faSort} />
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-6">
                    <div className="w-[20%] text-[14px] mr-4 font-[500]">
                      <label htmlFor="value">
                        Value {values.type === "Percentage" && <span>(%)</span>}{" "}
                        <span className="text-[red]">*</span>
                      </label>
                    </div>
                    <input
                      required
                      className="w-[30%] pr-[115px] h-[35px] mr-2 rounded border-[#0388CC] placeholder:text-[center] placeholder:text-[12px] text-[14px] placeholder:font-[400] font-[400]"
                      placeholder="Enter coupon value"
                      onChange={changeHandler}
                      value={values.value}
                      name="value"
                      type="number"
                      id="value"
                    />
                  </div>
                  {/* <div className="flex mt-6 text-[14px] font-[500]">
                    <div className="w-[20%] text-[14px] mr-4 font-[500]">
                      <label htmlFor="applies-to">
                        Applies To <span className="text-[red]">*</span>
                      </label>
                    </div>
                    <div className="w-[500px]">
                      <Select
                        isMulti
                        options={options}
                        onChange={multiSelectOnChange}
                      />
                    </div>
                  </div> */}
                  <div className="flex mt-6">
                    <div className="w-[20%] text-[14px] mr-4 font-[500]">
                      <label htmlFor="expiation-date">
                        Expiration Date <span className="text-[red]">*</span>
                      </label>
                    </div>
                    <input
                      required
                      className="w-[20%] h-[35px] mr-2 rounded border-[#0388CC] placeholder:text-[center] placeholder:text-[12px] text-[12px] font-[400] placeholder:font-[400]"
                      type="date"
                      onChange={changeHandler}
                      value={values.expirationDate}
                      name="expirationDate"
                      id="expiation-date"
                    />
                  </div>
                </div>
              </div>
              {error ? (
                <p className="text-[14px] ml-4 font-[400] mt-2 text-[red]">
                  <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                </p>
              ) : null}

              {/*footer*/}
              <div className="flex items-center justify-end p-3 rounded-b">
                <div className=" flex pb-[10px] ">
                  <div
                    onClick={() => setShowModal(false)}
                    className="rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                  >
                    Cancel
                  </div>
                  <input
                    type="submit"
                    className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                    value="Save"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading ? <Loading /> : null}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default NewCoupon;
