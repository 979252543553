import React, { useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Loading from "../Loading";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import NetworkError from "./NetworkError";
import Swal from "sweetalert2";
function AddFeatureType({ setShowModal, features, setFeatures }) {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    nameAr: "",
    nameEn: "",
  });
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitFeatureType = (e) => {
    e.preventDefault()
    if (values.nameAr !== "" && values.nameEn !== "") {
      setError(null);
      setLoading(true);
      axios
        .post(`${"https://api.camp-coding.site"}/products/feature/create`, {
          name_ar: values.nameAr,
          name_en: values.nameEn,
        })
        .then(function (response) {
          setLoading(false);

          setFeatures([...features, response.data]);
          setShowModal(false);
          Swal.fire({
            icon: "success",
            title: "Your changes had been saved",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(function (error) {
          if (error.code === "ERR_NETWORK") {
            setLoading(false);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
          }
          if (error.response.data?.message === "this Feature Already Exist") {
            setLoading(false);
            setError("Feature already exists.");
          }
        });
    } else {
      setError("Please fill all required fields");
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[85%]">
          <form onSubmit={submitFeatureType}>
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex justify-between h-[50px]  rounded-t">
              <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                Add New Feature Type
              </div>
              <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
            </div>
            {/* {body} */}
            <div className="relative p-6 w-full ">
              <div className="w-[90%]">
                <div className="flex justify-between ">
                  <label
                    className="text-[14px] mr-4 font-[400]"
                    htmlFor="nameAR"
                  >
                    Name-AR
                  </label>
                  <input
                    required
                    className="w-[90%] h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                    placeholder="Enter email"
                    onChange={changeHandler}
                    value={values.nameAr}
                    name="nameAr"
                    type="text"
                    id="nameAr"
                  />
                </div>
                <div className="flex justify-between mt-6">
                  <label
                    className="text-[14px] mr-4 font-[400]"
                    htmlFor="nameAR"
                  >
                    Name-EN
                  </label>
                  <input
                    required
                    className="w-[90%] h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                    placeholder="Enter email"
                    onChange={changeHandler}
                    value={values.nameEn}
                    name="nameEn"
                    type="text"
                    id="nameEn"
                  />
                </div>
              </div>
            </div>

            {error ? (
              <p className="text-[14px] ml-4 font-[400] mt-8 text-[red]">
                <FontAwesomeIcon icon={faCircleExclamation} /> {error}
              </p>
            ) : null}
            {/*footer*/}
            <div className="flex items-center justify-end p-3 rounded-b">
              <div className=" flex pb-[10px] ">
                <div
                  onClick={() => setShowModal(false)}
                  className="rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                >
                  Cancel
                </div>
              
                <input
                  required
                  type="submit"
                  className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                  value="Save"
                />
              </div>
            </div>
          </div></form>
        </div>
      </div>
      {loading ? <Loading /> : null}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default AddFeatureType;
