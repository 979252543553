import React, { useEffect, useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import PolicyForm from "../PolicyForm";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import axios from "axios";
import FileItem from "../FileUploader/FileItem";
import NetworkError from "../Modals/NetworkError";
import Loading from "../Loading";
import Swal from "sweetalert2";
function GeneralSettings() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["Admin"].includes(adminRole);

  const [adminData, setAdminData] = useState(null);

  const [values, setValues] = useState({});
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const bankInfoChange = (e) => {
    setBankInfo({ ...bankInfo, [e.target.name]: e.target.value });
  };

  const [bankInfo, setBankInfo] = useState({
    name:"",
    statement_number:"",
    account_number:"",
  });

  useEffect(() => {
    axios
      .get(
        "https://api.camp-coding.site" +
          "/users/getoneuser/" +
          currentUser?.user?.[0][1]
      )
      .then((response) => {
        const data = response.data;
        setAdminData(data);
        setValues({
          full_name: data.full_name,
          family_name: data.family_name,
          phone_number: data.phone_number,
          password: data.password,
          email: data.email,
          gender: data.gender,
        });
      });
    axios
      .get("https://api.camp-coding.site" + "/settings/findbank/")
      .then((response) => {
        const data = response.data;
        setBankInfo(data);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://api.camp-coding.site" + "/statistics/logo")
      .then((response) => {
        const data = response.data;
        setDatabaseLogo([data]);
      });
  }, []);

  const [logoUrl, setLogoUrl] = useState([]);

  const [files, setFiles] = useState([]);

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  const [logo, setLogo] = useState([]);
  const [logoData, setLogoData] = useState([]);
  const removeLogo = (filename, index) => {
    setLogo([]);
    setLogoData([]);
  };

  const [databaseLogo, setDatabaseLogo] = useState([]);
  const removeDatabaseLogo = (index) => {
    setDatabaseLogo(databaseLogo.filter((file, i) => i !== index));
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("file", logo[0], logo[0].name);
    await axios
      .post(
        `${"https://api.camp-coding.site"}/statistics/upload/logo`,
        formData
      )
      .then((imgResponse) => {
        setLogoUrl([imgResponse.data.imagePath]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateData = (e) => {
    e.preventDefault();
    if (
      (logo.length !== 0 || databaseLogo.length !== 0) &&
      values.family_name !== "" &&
      values.full_name !== "" &&
      values.email !== "" &&
      values.password !== ""
    ) {
      setError(null);
      setLoading(true);
      if (logo.length !== 0) {
        uploadImage();
      } else {
        setLogoUrl([...databaseLogo]);
      }
    } else {
      setError("Please fill all the required fields.");
    }
  };

  useEffect(() => {
    if (logoUrl.length !== 0) {
      updateAll();
    }
  }, [logoUrl]);

  const updateAll = async () => {
    axios
      .post(
        `${"https://api.camp-coding.site"}/users/update/user/${adminData.id}`,
        {
          lastName: values.full_name,
          firstName: values.family_name,
          email: values.email,
          phone: values.phone_number,
          gender: values.gender === "Male",
          logo_website: logoUrl?.[0],
        }
      )
      .then(function (response) {
        axios
          .post(`${"https://api.camp-coding.site"}/settings/updatebank`, bankInfo)
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Your changes had been saved",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <form onSubmit={updateData}>
      <div>
        <div className="flex">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">1.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#0388CC] font-bold text-[14px]">
              Personal Details
            </p>
            <div className="flex flex-wrap">
              <div className="w-[400px] mr-[120px] my-[10px]">
                <label className="text-[14px] font-[400]" htmlFor="full_name">
                  Full Name
                </label>
                <input
                  required
                  className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                  placeholder="Enter full name"
                  onChange={changeHandler}
                  type="text"
                  value={values.full_name}
                  id="full_name"
                  name="full_name"
                />
              </div>
              <div className="w-[400px] mr-[120px] my-[10px]">
                <label className="text-[14px] font-[400]" htmlFor="family_name">
                  Family
                </label>
                <input
                  required
                  className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                  placeholder="Enter family name"
                  onChange={changeHandler}
                  type="text"
                  value={values.family_name}
                  id="family_name"
                  name="family_name"
                />
              </div>
              <div className="w-[400px] mr-[120px] my-[10px]">
                <label
                  className="text-[14px] font-[400]"
                  htmlFor="phone_number"
                >
                  Phone Number
                </label>
                <input
                  required
                  className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                  placeholder="Enter phone number"
                  onChange={changeHandler}
                  type="tel"
                  value={values.phone_number}
                  id="phone_number"
                  name="phone_number"
                />
              </div>
              <div className="w-[400px] mr-[120px] my-[10px]">
                <label className="text-[14px] font-[400]" htmlFor="email">
                  Email
                </label>
                <input
                  required
                  className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                  placeholder="Enter email"
                  onChange={changeHandler}
                  type="text"
                  value={values.email}
                  id="email"
                  name="email"
                />
              </div>
              <div className="w-[400px] mr-[120px] my-[10px]">
                <label className="text-[14px] font-[400]" htmlFor="password">
                  Password
                </label>
                <input
                  required
                  disabled
                  className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                  placeholder="Enter password"
                  onChange={changeHandler}
                  type="password"
                  value={values.password}
                  id="password"
                  name="password"
                />
              </div>
              <div className="w-[400px] mr-[120px] my-[10px]">
                <div className="mt-[0px] text-[15px] font-[400] text-[#000]">
                  Gender
                </div>
                <label htmlFor="male" className="text-[14px] font-[400]">
                  Male
                </label>
                <input
                  required
                  className="ml-[10px] mr-[30px]"
                  type="radio"
                  checked={values.gender === "Male"}
                  onChange={() => {
                    setValues({ ...values, gender: "Male" });
                  }}
                  name="gender"
                  value="Male"
                  id="male"
                />
                <label htmlFor="female" className="text-[14px] font-[400]">
                  Female
                </label>
                <input
                  required
                  className="ml-[10px] mr-[30px]"
                  type="radio"
                  checked={values.gender === "Female"}
                  onChange={() => {
                    setValues({ ...values, gender: "Female" });
                  }}
                  name="gender"
                  value="Female"
                  id="female"
                />
              </div>
            </div>
            {/* <div>
            <div className="mt-[20px] text-[15px] font-[400] text-[#000]">
              Profile Picture
            </div>
            <p className="text-[#000]  text-[13px] font-[400]">
              Select a images of Products Page ( JPEG or PNG ):
              <span className="text-[red] font-[700]">*</span>
            </p>
            <div className=" w-[130px] h-[30px] mt-[5px]">
              <FileUpload
                files={files}
                setFiles={setFiles}
                removeFile={removeFile}
              />
            </div>
            <div className>
              <FileList files={files} removeFile={removeFile} />
            </div>
          </div> */}
          </div>
        </div>
        <div className="flex mt-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">2.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#0388CC] font-bold text-[14px]">Site Logo</p>
            <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
              Select a images of Product ( JPEG or PNG ):
              <span className="text-[red] font-[700]">*</span>
            </p>
            <div className=" w-[130px] h-[30px] mt-[5px]">
              <FileUpload
                required={logo?.length === 0 && databaseLogo?.length === 0}
                setDatabaseFile={setDatabaseLogo}
                files={logo}
                setFiles={setLogo}
                removeFile={removeLogo}
                setFileData={setLogoData}
                disabled2={disabled}
              />
            </div>
            <FileItem
              removeDatabaseFile={removeDatabaseLogo}
              databaseFile={databaseLogo}
              fileData={logoData}
              file={logo?.[0]}
              removeFile={removeLogo}
              index="0"
            />
          </div>
        </div>
        <div className="flex mt-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">3.</div>
          </div>
          <div className="ml-[6%]">
            <p className="text-[#0388CC] font-bold text-[14px]">
              Bank Informations
            </p>
            <div className="flex">
              <div>
                <div className="w-[400px] mr-[120px] my-[10px]">
                  <label className="text-[14px] font-[400]" htmlFor="bank-name">
                    Bank Name
                  </label>
                  <input
                    required
                    className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                    placeholder="Enter bank name"
                    onChange={bankInfoChange}
                    value={bankInfo.name}
                    name="name"
                    type="text"
                    id="bank-name"
                  />
                </div>
                <div className="w-[400px] mr-[120px] my-[10px]">
                  <label
                    className="text-[14px] font-[400]"
                    htmlFor="branch-name"
                  >
                    Account Number
                  </label>
                  <input
                    required
                    className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                    placeholder="Enter account number"
                    onChange={bankInfoChange}
                    value={bankInfo.account_number}
                    name="account_number"
                    type="text"
                    id="branch-name"
                  />
                </div>
                <div className="w-[400px] mr-[120px] my-[10px]">
                  <label
                    className="text-[14px] font-[400]"
                    htmlFor="branch-name"
                  >
                    Statement Number
                  </label>
                  <input
                    required
                    className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                    placeholder="Enter statement number"
                    onChange={bankInfoChange}
                    value={bankInfo.statement_number}
                    name="statement_number"
                    type="text"
                    id="branch-name"
                  />
                </div>
              </div>
              <div>
                <div className="w-[400px] h-[82%] mr-[120px] my-[10px]">
                  <label
                    className="text-[14px] font-[400]"
                    htmlFor="branch-name"
                  >
                    Message
                  </label>
                  <textarea
                    className="resize-none w-full h-full rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                    onChange={bankInfoChange}
                    value={bankInfo.message}
                    name="message"
                    id="branch-name"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showError ? <NetworkError setShowModal={setShowError} /> : null}
        {loading ? <Loading /> : null}
        <div className="absolute flex pb-[10px] right-[80px] bottom-[0px]">
          <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
            Cancel
          </div>
          <input
            type="submit"
            className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
}

export default GeneralSettings;
