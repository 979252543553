/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
// import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import styles from "../styles/selector.module.css";
import axios from "axios";

const optionss = {
  canceled: {
    title: "canceled",
    color: "text-[#D40017]",
    bgColor: "bg-[#D40017]",
  },
  pending: {
    title: "pending",
    color: "text-[#D8AA6B]",
    bgColor: "bg-[#D8AA6B]",
  },
  active: {
    title: "active",
    color: "text-[#0388CC]",
    bgColor: "bg-[#0388CC]",
  },
  paid: {
    title: "paid",
    color: "text-[#007530]",
    bgColor: "bg-[#007530]",
  },
};

const options = [
  {
    title: "canceled",
    value: "0",
    color: "text-[#D40017]",
    bgColor: "bg-[#D40017]",
  },
  {
    title: "pending",
    value: "1",
    color: "text-[#D8AA6B]",
    bgColor: "bg-[#D8AA6B]",
  },
  {
    title: "active",
    value: "2",
    color: "text-[#0388CC]",
    bgColor: "bg-[#0388CC]",
  },
  {
    title: "paid",
    value: "3",
    color: "text-[#007530]",
    bgColor: "bg-[#007530]",
  },
];


function StatusSelector({ status, orderId, disabled }) {
  const [orderStatus, setOrderStatus] = useState(status);
  const handleChange = async (event) => {
    await axios
      .get(
        `${"https://api.camp-coding.site"}/orders/update/${orderId}/${event.target.value}`
      )
      .then(setOrderStatus(event.target.value));
  };

  return (
    <div
      className={`${styles.selectBox} ${optionss[orderStatus].bgColor} min-w-[50px]`}
    >
      <select
        disabled={disabled}
        value={orderStatus}
        onChange={(e) => {
          handleChange(e);
        }}
        className={`${optionss[orderStatus].bgColor} pl-2 outline-none cursor-pointer font-sans rounded py-[3px]  border-none text-[white] w-[100%] `}
      >
        {options.map((option, index) => (
          <option
            // selected={option.title === orderStatus}
            value={option.title}
            key={index}
            className={`${option.color}  outline-[0px] hover:bg-[white] bg-[white] py-[4px] rounded`}
          >
            {option.title.charAt(0).toLocaleUpperCase() + option.title.slice(1)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default StatusSelector;
