import axios from "axios";

const API_URL =
"https://api.camp-coding.site/auth/user/"
  // "https://sea-turtle-app-qz5px.ondigitalocean.app/auth/user/";


const login = (email, password) => {
  return axios
    .post(API_URL + "signin", {
      email,
      password,
    })
    .then((response) => {
      return response.data;
    }).catch((err)=>{
      console.log(err)
    })
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  login,
  logout,
};

export default authService;
