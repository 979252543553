import React, { useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
function DeleteWarning({ setShowModal, itemToDelete, url, setItems, items}) {
  const onDelete = async () => {
    await axios
      .get("https://api.camp-coding.site" + url + itemToDelete.id)
      .then((res) => {
        removeItem(itemToDelete.index)
        setShowModal(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeItem = (index) => {
    setItems(items.filter((item, i) => i !== index));
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[30%]">
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex flex-col jusitify-between">
              <div className="m-auto mt-2">
                <img className="w-[150px]" src="./images/warning.png" />
              </div>
              <div className="font-[400] text-[15px] m-auto">Are you sure you want to delete this item?</div>
              <div className="flex justify-evenly">
                <div
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className="cursor-pointer rounded text-[#fff] font-[300] my-4 px-3 py-2 bg-[#0388CC]"
                >
                  Cancel
                </div>
                <div onClick={onDelete} className="cursor-pointer rounded text-[#fff] font-[300] my-4 px-3 py-2 bg-[#ff3333]">
                  Delete
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default DeleteWarning;
