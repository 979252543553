import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/adminUserColumns";
import {
  faCaretDown,
  faCirclePlus,
  faGear,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NewUser from "../Modals/NewUser";
import { selectUser } from "../../redux/slices/userSlice";
import DeleteWarning from "../Modals/DeleteWarning";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
import UserRole from "../UserRole";
function AdminUsers() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["Admin"].includes(adminRole);
  const [showModal, setShowModal] = React.useState(false);

  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;

  const [users, setUsers] = useState([]);
  var data = [];
  const fetchUsers = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/users/allusers`
    );
    const data = response.data;
    if (data.length !== users.length) {
      setUsers(data);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [users]);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(users);
  }, [users]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        users.filter((user) => {
          return user.email.includes(searchInput);
        })
      );
    } else {
      setFilteredData(users);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    const user = filteredData[i];
    data = [
      ...data,
      {
        id: user.id,
        email: user.email,
        phone: user.phone_number,
        role: (
          <div className="z-50 overflow-visible l-[35px] px-1 font-[400] text-[white] py-[2px] min-w-[100px] rounded-md">
            <UserRole
              disabled={!["Admin"].includes(adminRole)}
              key={user.role}
              userId={user.id}
              role={user.role}
            />
          </div>
        ),
        registred: user.createdAt?.slice(0, 10),
        delete: (
          <div
            onClick={() => {
              if (!disabled) {
                setItemToDelete({ id: user.id, index: i });
                setShowWarningModal(true);
              }
            }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }
  return (
    <div className="flex flex-col justify-between">
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewUser
          users={users}
          setUsers={setUsers}
          setShowModal={setShowModal}
        />
      ) : null}
      {showWarningModal ? (
        <DeleteWarning
          url="/users/delete/"
          setShowModal={setShowWarningModal}
          itemToDelete={itemToDelete}
          items={users}
          setItems={setUsers}
        />
      ) : null}
      <div
        onClick={() => !disabled && setShowModal(true)}
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[0px] ml-[20px] text-[13px]"
      >
        <div className="mr-2">Add new user </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </div>
  );
}

export default AdminUsers;
