import { faCaretDown, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/userSlice";
import styles from "../styles/profilepopup.module.css";
import { selectUser } from "../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function UserIcon() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signOut = () => {
    dispatch(logout());
    navigate("/login");
  };
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];

  const [adminData, setAdminData] = useState(null);

  const fetchAdmin = async () => {
    await axios
      .get(
        "https://api.camp-coding.site" +
          "/users/getoneuser/" +
          currentUser?.user?.[0]?.[1]
      )
      .then((response) => {
        setAdminData(response.data);
      })
      .catch((error) => {
        if (error.response.data.message === "user not exist") {
          signOut();
        }
      });
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  const downloadSheet = async () => {
    const href =
      "https://api.camp-coding.site" + "/excel-file/exportreportsales";

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "Sales Report.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <div className={`px-2 min-w-fit m-auto mr-0 flex ${styles.sideBtn}`}>
      <div className="cursor-pointer">
        <span className="text-[#00AEEF] mr-1">
          <FontAwesomeIcon icon={faUser} />
        </span>{" "}
        <FontAwesomeIcon icon={faCaretDown} />
      </div>
      <div className={`${styles.popup} ${styles.arrowTop}`}>
        <div
          className={`text-[12px]  text-start font-bold divide-y ${styles.popupWrapper}`}
        >
          <div className="mt-[10px] opacity-[43%] text-[#0F3F62] text-[13px]">
            Signed in as <br></br> {adminData?.email}
          </div>
          <div className="mt-[10px] font-[600]">
            <div
              onClick={downloadSheet}
              className="flex cursor-pointer text-[#0F3F62] py-[10px]"
            >
              <img className="mr-4 w-[15px]" src="/images/chart.svg" /> Export
              Sales Report
            </div>
            <Link to={["Admin"].includes(adminRole) ? "/settings?tab=3" : "#"}>
              <div className="flex text-[#0F3F62] py-[10px]">
                <img className="mr-4 w-[15px]" src="/images/location.svg" />{" "}
                Shipment Fee
              </div>
            </Link>
            <Link to={["Admin"].includes(adminRole) ? "/settings?tab=1" : "#"}>
              <div className="flex text-[#0F3F62] py-[10px]">
                <img className="mr-4 w-[15px]" src="/images/settings.svg" />{" "}
                Settings
              </div>
            </Link>
            <div
              onClick={() => signOut()}
              className="cursor-pointer flex text-[#0F3F62] py-[10px]"
            >
              <img className="mr-4 w-[15px]" src="/images/logout.svg" /> Sign
              Out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserIcon;
