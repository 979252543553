import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import NewProduct from "../components/Modals/NewProduct";

import {
  faUser,
  faCaretDown,
  faCirclePlus,
  faUpload,
  faDownload,
  faGear,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../global/columns/productColumns";
import axios from "axios";
import ApprovalSelector from "../components/ApprovalSelector";
import { Link } from "react-router-dom";
import { setProduct } from "../redux/slices/itemSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";
import DeleteWarning from "../components/Modals/DeleteWarning";
import UploadModal from "../components/Modals/UploadModal";
import Breadcrumbs from "../components/Breadcrumbs";
import SearchBar from "../components/SearchBar";
import UserIcon from "../components/UserIcon";
import { selectSearch } from "../redux/slices/itemSlice";
import { setSearch } from "../redux/slices/itemSlice";
import Footer from "../components/Footer";
function Products() {
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);
  const dispatch = useDispatch();
  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };

  const showCellBorders = false;
  const showZebraRows = false;
  const [rowHeight, setRowHeight] = useState(60);
  const [showProductModal, setShowProductModal] = React.useState(false);
  const [showUploadModal, setShowUploadModal] = React.useState(false);

  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/categories/findall`
    );
    setCategories(response.data);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const [products, setProducts] = useState([]);
  var data = [];
  // var categoriesNames = [];
  // const [categoriesNamesState, setCategoriesNamesState] = useState([]);
  const fetchProducts = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/products/allProducts`
    );
    const data = response.data;
    // for (let i = 0; i < data.length; i++) {
    //   if (data[i].category_id) {
    //     await axios
    //       .get(
    //         `${"https://api.camp-coding.site"}/categories/${data[i].category_id}`
    //       )
    //       .then((response) => {
    //         categoriesNames = [
    //           ...categoriesNames,
    //           response.data.category_translations[1].name,
    //         ];
    //       });
    //   } else {
    //     categoriesNames = [...categoriesNames, null];
    //   }
    // }
    // setCategoriesNamesState(categoriesNames);
    if (data.length !== products.length) {
      setProducts(data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [products]);

  const downloadSheet = async () => {
    const href = "https://api.camp-coding.site" + "/excel-file/exportproducts";

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "products.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(products);
  }, [products]);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        products.filter((product) => {
          return product.name.includes(searchInput);
        })
      );
    } else {
      setFilteredData(products);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    const product = filteredData[i];
    data = [
      ...data,
      {
        image: (
          <img
            className="h-[50px] m-auto"
            src={
              product.product_images?.length !== 0 &&
              product.product_images !== undefined
                ? `${"https://api.camp-coding.site"}/${product.product_images?.[0].image_url}`
                : "/images/no-image.jpeg"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/no-image.jpeg";
            }}
          />
        ),
        name: product?.product_translations?.[0].nameProduct,
        category: product?.category?.category_translations[1]?.name,
        sku: product.sku_code,
        price: product.price,
        lastUpdated: product.updatedAt.slice(0, 10),
        inStock: product.stock + " pcs",
        status: (
          <ApprovalSelector
            key={product.status}
            productId={product.id}
            status={product.status}
            disabled={!["DataEntry", "Admin"].includes(adminRole)}
          />
        ),
        view: (
          <Link
            to={
              ["DataEntry", "Admin"].includes(adminRole)
                ? `/product-details?tab=1`
                : "#"
            }
          >
            <div
              onClick={() => dispatch(setProduct({ id: product.id }))}
              className="bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
          onClick={() => {
            if (!disabled) {
              setItemToDelete({ id: product.id, index: i });
              setShowWarningModal(true);
            }
          }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>
        <div className="w-full flex flex-col ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <Breadcrumbs
              breads={[
                {
                  title: "Products",
                  link: "/products",
                },
              ]}
            />
            <div className="flex items-center h-8 w-[30%]">
              <SearchBar placeholder="Search by product name"/>
              <UserIcon />
            </div>
          </div>
          <div className="bg-[white] flex flex-col justify-between grow mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
            <div className=" p-3 w-full">
              <ReactDataGrid
                idProperty="id"
                columns={columns}
                showCellBorders={showCellBorders}
                showZebraRows={showZebraRows}
                style={gridStyle}
                rowHeight={rowHeight}
                dataSource={data}
              />
            </div>
            {showProductModal ? (
              <NewProduct
                products={products}
                setProducts={setProducts}
                setShowModal={setShowProductModal}
              />
            ) : null}
            {showWarningModal ? (
              <DeleteWarning
                url="/products/delete/"
                setShowModal={setShowWarningModal}
                itemToDelete={itemToDelete}
                items={products}
                setItems={setProducts}
              />
            ) : null}
            {showUploadModal ? (
              <UploadModal setShowModal={setShowUploadModal} />
            ) : null}
            <div className="flex">
              <div
                onClick={() =>
                  ["DataEntry", "Admin"].includes(adminRole) &&
                  setShowProductModal(true)
                }
                className="cursor-pointer flex mr-8 items-center text-[#0388CC] h-[30px] mb-[15px] ml-[25px] text-[13px]"
              >
                <div className="mr-2">Add new product </div>
                <div className="text-[22px]">
                  <FontAwesomeIcon icon={faCirclePlus} />
                </div>
              </div>
              <div
                onClick={downloadSheet}
                className="cursor-pointer flex mr-8 items-center text-[#0388CC] h-[30px] mb-[15px] ml-[25px] text-[13px]"
              >
                <div className="mr-2">Download Products Sheet </div>
                <div className="bg-[#D40017] rounded-full pt-[1px] w-[22px] h-[22px] text-center text-[#fff] text-[13px]">
                  <FontAwesomeIcon icon={faDownload} />
                </div>
              </div>
              <div className="cursor-pointer flex mr-8 items-center text-[#0388CC] h-[30px] mb-[15px] ml-[25px] text-[13px]">
                <div onClick={() => setShowUploadModal(true)} className="mr-2">
                  Upload Products Sheet{" "}
                </div>
                <div className="bg-[#D8AA6B] rounded-full pt-[1px] w-[22px] h-[22px] text-center text-[#fff] text-[13px]">
                  <FontAwesomeIcon icon={faUpload} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Products;
