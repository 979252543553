import React, { useEffect, useState } from "react";
import { faBell, faComment, faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/jobApplicationColumns";
import axios from "axios";
import JobStatus from "../JobStatus";
import { Link } from "react-router-dom";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
import { selectUser } from "../../redux/slices/userSlice";
import JobApplicationStatus from "../JobApplicationStatus";
import ApplicationStatus from "../ApplicationStatus";
function JobApplications() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const [showModal, setShowModal] = React.useState(false);

  const [jobApplications, setJobApplications] = useState([]);

  const fetchJobApplications = async () => {
    await axios
      .get("https://api.camp-coding.site" + "/careers/jobapplication/findall")
      .then((res) => {
        setJobApplications(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchJobApplications();
  }, []);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(jobApplications);
  }, [jobApplications]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        jobApplications.filter((job) => {
          return job.email.includes(searchInput);
        })
      );
    } else {
      setFilteredData(jobApplications);
    }
  }, [searchInput]);

  var data = [];

  for (let i = 0; i < filteredData?.length; i++) {
    const jobApplication = filteredData[i];
    data = [
      ...data,
      {
        id: jobApplication.id,
        name: jobApplication.full_name,
        email: jobApplication.email,
        age: new Date().getFullYear() - jobApplication.birdthday.slice(0, 4),
        phone: jobApplication.phone_number,
        jobPosition: jobApplication.job_management?.name_ar,
        applicationDate: jobApplication.CreatedAt.slice(0, 10),
        status: (
          <div className="z-50 overflow-visible l-[35px] px-1 font-[400] text-[white] py-[2px] min-w-[100px] rounded-md">
            <ApplicationStatus
              disabled={!["HR", "Admin"].includes(adminRole)}
              key={jobApplication.status}
              applicationId={jobApplication.id}
              status={jobApplication.status}
            />
          </div>
        ),
        view: (
          <Link
            to={["HR", "Admin"].includes(adminRole) ? `/job-application-info?id=${jobApplication.id}` : "/careers?tab=1"}
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        // view: (
        //   <Link to={`/job-application-info?id=${jobApplication.id}`}>
        //     <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
        //       <FontAwesomeIcon icon={faEye} />
        //     </div>
        //   </Link>
        // ),
      },
    ];
  }

  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  return (
    <div className=" p-3 w-full">
      <ReactDataGrid
        idProperty="id"
        columns={columns}
        showCellBorders={showCellBorders}
        showZebraRows={showZebraRows}
        style={gridStyle}
        dataSource={data}
      />
    </div>
  );
}

export default JobApplications;
