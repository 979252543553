import React, { useState } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import RichTextEditor from "../RichTextEditor";
import SettingsTab from "../SettingsTab";
import PolicyForm from "../PolicyForm";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/settingsTabs.module.css";
import FileItem from "../FileUploader/FileItem";
import axios from "axios";
import Loading from "../Loading";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import NetworkError from "./NetworkError";
import Swal from "sweetalert2";
function NewCookCategory({
  setShowModal,
  cooksCategories,
  setCooksCategories,
}) {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  //Category Image
  const [categoryImage, setCategoryImage] = useState([]);
  const [categoryImageData, setCategoryImageData] = useState([]);
  const removeCategoryImage = (filename, index) => {
    setCategoryImage(null);
    setCategoryImageData(null);
  };

  const [descAr, setDescAr] = useState("");
  const [descEn, setDescEn] = useState("");

  const [values, setValues] = useState({
    nameEn: "",
    nameAr: "",
  });
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitCooksCategory = async (e) => {
    e.preventDefault();
    if (
      values.nameAr !== "" &&
      values.nameEn !== "" &&
      descAr !== "" &&
      descEn !== "" &&
      categoryImage.length !== 0
    ) {
      setError(null);
      setLoading(true);
      const formData = new FormData();
      formData.append("file", categoryImage[0], categoryImage[0].name);
      await axios
        .post(
          `${"https://api.camp-coding.site"}/categories/upload/category-image`,
          formData
        )
        .then((imgResponse) => {
          axios
            .post(`${"https://api.camp-coding.site"}/cooks-categories/create`, {
              name_en: values.nameEn,
              name_ar: values.nameAr,
              description_en: descEn,
              description_ar: descAr,
              image_url: imgResponse.data.imagePath,
            })
            .then(function (response) {
              setLoading(false);
              setCooksCategories([...cooksCategories, response.data]);
              setShowModal(false);
              Swal.fire({
                icon: "success",
                title: "Your changes had been saved",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch((err) => {
          // inform the user
          console.error(err);
          if (err.code === "ERR_NETWORK") {
            setLoading(false);
            setShowError(true);
            setTimeout(() => {
              setShowError(false);
            }, 3000);
          }
        });
    } else {
      setError("Please fill all required fields");
    }
  };
  return (
    <>
      <div className="justify-center mt-[-30px] h-[800px] items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 w-[85%]">
          <form onSubmit={submitCooksCategory}>
            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between h-[50px]  rounded-t">
                <div className="text-[18px] w-[20%] text-center m-auto  font-semibold">
                  Add New Cook Category
                </div>
                <div className="w-[80%] rounded-bl-xl rounded-tr-xl bg-[#E9F6FD] h-full"></div>
              </div>
              {/* {body} */}
              <div className="relative h-[600px] p-6 w-full scrollbar-thin h-full scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full ">
                <div>
                  <div className="flex flex-wrap">
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="name-en"
                      >
                        Name - EN
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Cook cetegory name in english"
                        onChange={changeHandler}
                        value={values.nameEn}
                        name="nameEn"
                        type="text"
                        id="name-en"
                      />
                    </div>
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="name-ar"
                      >
                        Name - AR
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Cook cetegory name in arabic"
                        onChange={changeHandler}
                        value={values.nameAr}
                        name="nameAr"
                        type="text"
                        id="name-ar"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-[30px] w-[87%]">
                    <RichTextEditor
                      name="Description-EN"
                      initialValue=""
                      getValue={setDescEn}
                    />
                    <RichTextEditor
                      name="Description-AR"
                      initialValue=""
                      getValue={setDescAr}
                    />
                  </div>
                  <div className="mt-6">
                    <p className="text-[#000] text-[14px] font-[400]">
                      Select a images of catgory ( JPEG or PNG ):
                      <span className="text-[red] font-[700]">*</span>
                    </p>
                    <div className=" w-[130px] h-[30px] mt-[5px]">
                      <FileUpload
                        required={true}
                        files={categoryImage}
                        setFiles={setCategoryImage}
                        removeFile={removeCategoryImage}
                        setFileData={setCategoryImageData}
                      />
                    </div>
                    <FileItem
                      fileData={categoryImageData}
                      file={categoryImage?.[0]}
                      removeFile={removeCategoryImage}
                      index="0"
                    />
                  </div>
                </div>
                {error ? (
                  <p className="text-[14px] mt-2 text-[red]">
                    <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                  </p>
                ) : null}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-3 rounded-b">
                <div className=" flex pb-[10px] ">
                  <div
                    onClick={() => setShowModal(false)}
                    className="rounded-full cursor-pointer text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]"
                  >
                    Cancel
                  </div>
                  <input
                    type="submit"
                    className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                    value="Save"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading ? <Loading /> : null}
      {showError ? <NetworkError setShowModal={setShowError} /> : null}
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default NewCookCategory;
