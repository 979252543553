import React, { useState, useEffect } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileItem from "../FileUploader/FileItem";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { selectCooksCategory } from "../../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Loading";
import NetworkError from "../../components/Modals/NetworkError";
import Swal from "sweetalert2";
function EditCooksCategory() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Database files
  const [databaseImage, setDatabaseImage] = useState([]);
  const removeDatabaseImage = (index) => {
    setDatabaseImage(databaseImage.filter((file, i) => i !== index));
  };
  const selectedCooksCategory = useSelector(selectCooksCategory);
  const [values, setValues] = useState({});

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [category, setCategory] = useState({});
  const [descAr, setDescAr] = useState(false);
  const [descEn, setDescEn] = useState(false);
  const fetchCooksCategory = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/cooks-categories/${selectedCooksCategory.id}`
    );
    setValues({
      nameEn: response.data.cooks_category_translations[1]?.title,
      nameAr: response.data.cooks_category_translations[0]?.title,
    });
    setDescAr(response.data.cooks_category_translations[0]?.description);
    setDescEn(response.data.cooks_category_translations[1]?.description);
    setDatabaseImage([response.data.image_url]);
  };

  useEffect(() => {
    fetchCooksCategory();
  }, []);

  const [imageUrl, setImageUrl] = useState([]);

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("file", categoryImage[0], categoryImage[0].name);
    await axios
      .post(
        `${"https://api.camp-coding.site"}/categories/upload/category-image`,
        formData
      )
      .then((imgResponse) => {
        setImageUrl([imgResponse.data.imagePath]);
      })
      .catch((err) => {
        // inform the user
        console.error(err);
      });
  };

  useEffect(() => {
    if (imageUrl !== "") {
      updateAll();
    }
  }, [imageUrl]);

  const updateAll = async () => {
    axios
      .post(
        `${"https://api.camp-coding.site"}/cooks-categories/update/${selectedCooksCategory.id}`,
        {
          name_en: values.nameEn,
          name_ar: values.nameAr,
          description_en: descEn,
          description_ar: descAr,
          image_url: imageUrl[0],
        }
      )
      .then(function (response) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  const updateCategory = (e) => {
    e.preventDefault()
    if (
      (categoryImage.length !== 0 || databaseImage.length !== 0) &&
      values.nameAr !== "" &&
      values.nameEn !== "" &&
      descAr !== "" &&
      descEn !== ""
    ) {
      setError(null);
      setLoading(true);
      if (categoryImage.length !== 0) {
        uploadImage();
      } else {
        setImageUrl([...databaseImage]);
      }
    } else {
      setError("Please fill all the required fields.");
    }
  };

  //Category Image
  const [categoryImage, setCategoryImage] = useState([]);
  const [categoryImageData, setCategoryImageData] = useState([]);
  const removeCategoryImage = (filename, index) => {
    setCategoryImage(null);
    setCategoryImageData(null);
  };
  return (
    <form onSubmit={updateCategory}>
      <div className="ml-[6.5%]">
        <div className="flex flex-wrap">
          <div className="w-[40%] mr-[20px] my-[10px]">
            <label className="text-[14px] font-[500]" htmlFor="name-en">
              Name - EN
              <span className="text-[red] font-[700]">*</span>
            </label>
            <input
              required
              className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              placeholder="Cook cetegory name in english"
              onChange={changeHandler}
              value={values.nameEn}
              name="nameEn"
              type="text"
              id="name-en"
            />
          </div>
          <div className="w-[40%] mr-[20px] my-[10px]">
            <label className="text-[14px] font-[500]" htmlFor="name-ar">
              Name - AR
              <span className="text-[red] font-[700]">*</span>
            </label>
            <input
              required
              className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              placeholder="Cook cetegory name in arabic"
              onChange={changeHandler}
              value={values.nameAr}
              name="nameAr"
              type="text"
              id="name-ar"
            />
          </div>
        </div>
        <div className="flex flex-wrap mt-[30px] w-[87%]">
          {descEn !== false && (
            <RichTextEditor
              name="Description-EN"
              initialValue={descEn}
              getValue={setDescEn}
            />
          )}
          {descAr !== false && (
            <RichTextEditor
              name="Description-AR"
              initialValue={descAr}
              getValue={setDescAr}
            />
          )}
        </div>
        <div className="mt-6">
          <p className="text-[#000] text-[14px] font-[400]">
            Select a images of catgory ( JPEG or PNG ):
            <span className="text-[red] font-[700]">*</span>
          </p>
          <div className=" w-[130px] h-[30px] mt-[10px]">
            <FileUpload
              required={databaseImage?.length===0 && categoryImage?.length===0}
              setDatabaseFile={setDatabaseImage}
              files={categoryImage}
              setFiles={setCategoryImage}
              setFileData={setCategoryImageData}
              removeFile={removeCategoryImage}
            />
          </div>
          <div className>
            <FileItem
              removeDatabaseFile={removeDatabaseImage}
              databaseFile={databaseImage}
              fileData={categoryImageData}
              file={categoryImage?.[0]}
              removeFile={removeCategoryImage}
              index="0"
            />
          </div>
          {error ? (
            <p className="text-[14px] mt-2 text-[red]">
              <FontAwesomeIcon icon={faCircleExclamation} /> {error}
            </p>
          ) : null}
        </div>
        {loading ? <Loading /> : null}
        {showError ? <NetworkError setShowModal={setShowError} /> : null}
        <div className="flex mb-2 absolute bottom-0 right-12 pb-[20px] ">
          <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
            Cancel
          </div>
          <input
            className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
            type="submit"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
}

export default EditCooksCategory;
