import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/newsColumns";
import {
  faCirclePlus,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NewNews from "../Modals/NewNews";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import DeleteWarning from "../Modals/DeleteWarning";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
function News() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);

  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  const [showModal, setShowModal] = React.useState(false);

  const [news, setNews] = useState([]);
  var data = [];
  const fetchNews = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/news/findall`
    );
    const data = response.data;
    if (data.length !== news.length) {
      setNews(data);
    }
  };

  useEffect(() => {
    fetchNews();
  }, [news]);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(news);
  }, [news]);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        news.filter((oneNews) => {
          return oneNews.new_translations[0]?.title.includes(searchInput);
        })
      );
    } else {
      setFilteredData(news);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    data = [
      ...data,
      {
        title: filteredData[i].new_translations[0]?.title,
        description: filteredData[i].new_translations[0]?.descrition,
        lastUpdated: filteredData[i].updatedAt?.slice(0, 10),
        view: (
          <Link
            to={
              !disabled ? `/news-edit?id=${filteredData[i].id}` : "/news?tab=1"
            }
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
            onClick={() => {
              if (!disabled) {
                setItemToDelete({ id: filteredData[i].id, index: i });
                setShowWarningModal(true);
              }
            }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  return (
    <div className="flex flex-col justify-between">
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewNews news={news} setNews={setNews} setShowModal={setShowModal} />
      ) : null}
      {showWarningModal ? (
        <DeleteWarning
          url="/news/delete/"
          setShowModal={setShowWarningModal}
          itemToDelete={itemToDelete}
          items={news}
          setItems={setNews}
        />
      ) : null}
      <div
        onClick={() => !disabled && setShowModal(true)}
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[0px] ml-[20px] text-[13px]"
      >
        <div className="mr-2">Add new news </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </div>
  );
}

export default News;
