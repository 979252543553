import React, { useState, useCallback, useEffect } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileList from "../FileUploader/FileList";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import NewSubCategory from "../Modals/NewSubCategory";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faEye,
  faFileAlt,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import columns from "../../global/columns/categoryColumns";
import { selectCategory } from "../../redux/slices/itemSlice";
import { useDispatch } from "react-redux";
import { setCategory } from "../../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { selectSearch } from "../../redux/slices/itemSlice";
import { setSearch } from "../../redux/slices/itemSlice";
function SubCategories() {
  const searchInput = useSelector(selectSearch);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  var data = [];
  const selectedCategory = useSelector(selectCategory);
  const [showModal, setShowModal] = React.useState(false);

  const gridStyle = {
    minHeight: 530,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  const [rowHeight, setRowHeight] = useState(60);
  const [expandedNodes, setExpandedNodes] = useState({});
  const onExpandedNodesChange = useCallback(({ expandedNodes }) => {
    setExpandedNodes(expandedNodes);
  }, []);

  var products = [];
  const [productsByCategory, setProductsByCategory] = useState([]);
  const fetchCategories = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/categories/findchildrens/${selectedCategory}`
    );
    setCategories(response.data);
    for (let i = 0; i < response.data.length; i++) {
      var response2 = await axios.get(
        `${"https://api.camp-coding.site"}/products/allProductsByCategory/${response.data[i].id}`
      );
      products = [...products, response2.data];
    }
    setProductsByCategory(products);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(categories);
  }, [categories]);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        categories.filter((category) => {
          return category.category_translations[1].name.includes(searchInput);
        })
      );
    } else {
      setFilteredData(categories);
    }
  }, [searchInput]);

  for (let i = 0; i < categories.length; i++) {
    data = [
      ...data,
      {
        image: (
          <img
            className="h-[50px] m-auto"
            src={`${"https://api.camp-coding.site"}/${categories[i].image_url}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/no-image.jpeg";
            }}
          />
        ),
        id: categories[i].id,
        name: categories[i].category_translations
          ? categories[i].category_translations[1].name
          : null,
        productsByCat: productsByCategory[i]?.map(
          (product) => product.product_translations[0].nameProduct + ","
        ),
        products: productsByCategory[i]?.length,
        lastUpdate: categories[i].updatedAt.slice(0, 10),
        view: (
          <a href={`/category-details?tab=1&id=${categories[i].id}`}>
            <div
              onClick={() => dispatch(setCategory(categories[i].id))}
              className="bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
            >
              <FontAwesomeIcon icon={faEye} />
            </div>
          </a>
        ),
        folder: true,
      },
    ];
  }

  return (
    <>
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          rowHeight={rowHeight}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? <NewSubCategory setShowModal={setShowModal} /> : null}
      <div
        onClick={() => setShowModal(true)}
        className="cursor-pointer  flex items-center text-[#0388CC] h-[30px] absolute bottom-2  text-[13px]"
      >
        <div className="mr-2">Add new category </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </>
  );
}

export default SubCategories;
