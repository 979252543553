import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";

import {
  faUser,
  faCaretDown,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileUpload from "../components/FileUploader/FileUpload";
import RichTextEditor from "../components/RichTextEditor";

import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import FileItem from "../components/FileUploader/FileItem";
import FileList from "../components/FileUploader/FileList";
import FileMultiUpload from "../components/FileUploader/FileMultiUpload";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import Loading from "../components/Loading";
import { selectCooksCategory } from "../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import Breadcrumbs from "../components/Breadcrumbs";
import UserIcon from "../components/UserIcon";
import NetworkError from "../components/Modals/NetworkError";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
function CookEdit() {
  const [showError, setShowError] = useState(false);
  const selectedCooksCategory = useSelector(selectCooksCategory);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  let { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let cookId = queryParams.get("id");

  // FETCHING PRODUCTS

  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/products/allProducts`
    );
    const data = response.data;
    if (data.length !== products.length) {
      setProducts(data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const [values, setValues] = useState({
    nameAr: "",
    nameEn: "",
    videoURL: "eferferg",
    standards: "",
    ingredients_en: "",
    recommandedProducts: "",
    video_url_instead_slider: "",
  });

  const [descAr, setDescAr] = useState(false);
  const [descEn, setDescEn] = useState(false);

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  //Slider Files
  const [sliderFiles, setSliderFiles] = useState([]);
  const [sliderFilesData, setSliderFilesData] = useState([]);
  const removeSliderFile = (filename, index) => {
    setSliderFiles(sliderFiles.filter((file, i) => i !== index));
    setSliderFilesData(sliderFilesData.filter((fileData, i) => i !== index));
  };

  //Background Image
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [backgroundImageData, setBackgroundImageData] = useState([]);
  const removeBackgroundImage = (filename, index) => {
    setBackgroundImage(null);
    setBackgroundImageData(null);
  };

  //NEW FILES
  const [newSlider, setNewSlider] = useState();
  const [newBackgroundImage, setNewBackgroundImage] = useState();

  // DATABASE FILES
  const [databaseSlider, setDatabaseSlider] = useState([]);
  const removeDatabaseSlider = (index) => {
    setDatabaseSlider(databaseSlider.filter((file, i) => i !== index));
  };

  const [databaseBackgroundImage, setDatabaseBackgroundImage] = useState([]);
  const removeDatabaseBackgroundImage = (index) => {
    setDatabaseBackgroundImage(
      databaseBackgroundImage.filter((file, i) => i !== index)
    );
  };

  const [cook, setCook] = useState({});

  const fetchCook = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/cooks/find/${cookId}`
    );
    const data = response.data;
    setValues({
      nameEn: data.cooks_translations[1]?.name,
      nameAr: data.cooks_translations[0]?.name,
      videoURL: data.video_url,
      standards: data.ingrendients,
      ingredients_en: data.ingredients_en,
      recommandedProducts: "",
      video_url_instead_slider: data.video_url_instead_slider,
    });
    setDescAr(data.cooks_translations[0]?.description);
    setDescEn(data.cooks_translations[1]?.description);
    setCook(data);

    setDatabaseBackgroundImage([data.image_background_url]);
    let images = [];
    data.cooks_images.map((item) => {
      images = [...images, item.image_url];
    });
    setDatabaseSlider(images);
  };

  useEffect(() => {
    fetchCook();
  }, []);

  // UPDATING DATA
  const uploadImageCategory = async (
    imageCategory,
    setNewImagecategory,
    setDatabaseImagecategory,
    uploadedFiles
  ) => {
    var databaseImages = [];
    for (let i = 0; i < uploadedFiles.length; i++) {
      const formData = new FormData();
      formData.append("file", uploadedFiles[i], uploadedFiles[i].name);
      await axios
        .post(
          `${"https://api.camp-coding.site"}/cooks/upload/cook-image`,
          formData
        )
        .then((imgResponse) => {
          databaseImages = [...databaseImages, imgResponse.data.imagePath];
        })
        .catch((err) => {
          // inform the user
          console.log(err);
        });
    }
    setNewImagecategory([...imageCategory, ...databaseImages]);
    setDatabaseImagecategory([...imageCategory, ...databaseImages]);
  };

  const updateData = (e) => {
    e.preventDefault();
    if (
      (sliderFiles.length !== 0 || databaseSlider.length !== 0) &&
      (backgroundImage.length !== 0 || databaseBackgroundImage.length !== 0) &&
      values.nameAr !== "" &&
      values.nameEn !== "" &&
      values.standards !== "" &&
      values.ingredients_en !== "" &&
      descAr !== "" &&
      descEn !== ""
    ) {
      setError(null);
      setLoading(true);
      uploadImageCategory(
        databaseSlider,
        setNewSlider,
        setDatabaseSlider,
        sliderFiles
      );
    } else {
      setError("Please fill all the required fields.");
    }
  };

  useEffect(() => {
    if (newSlider) {
      uploadBackgroundImage();
    }
  }, [newSlider]);

  useEffect(() => {
    if (newBackgroundImage) {
      uploadAll();
    }
  }, [newBackgroundImage]);

  const uploadAll = async () => {
    setSliderFiles([]);
    setBackgroundImage([]);
    await axios
      .post(`${"https://api.camp-coding.site"}/cooks/update/${cookId}`, {
        photo_slider_urls: newSlider,
        name_en: values.nameEn,
        name_ar: values.nameAr,
        description_en: descEn,
        description_ar: descAr,
        ingredients: values.standards,
        ingredients_en: values.ingredients_en,
        id_category_cook: cook.cooks_categoryId,
        image_background_url: newBackgroundImage[0],
        video_url_instead_slider: values.video_url_instead_slider,
        image_url: newSlider,
        video_url: values.videoURL,
        recommended_product_ids: values.recommandedProducts,
      })
      .then(function (response) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  const uploadBackgroundImage = async () => {
    if (backgroundImage.length > 0) {
      const formData = new FormData();
      formData.append("file", backgroundImage[0], backgroundImage[0].name);
      await axios
        .post(
          `${"https://api.camp-coding.site"}/cooks/upload/cook-image`,
          formData
        )
        .then((imgResponse) => {
          setNewBackgroundImage([imgResponse.data.imagePath]);
          setDatabaseBackgroundImage([imgResponse.data.imagePath]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setNewBackgroundImage([...databaseBackgroundImage]);
    }
  };

  const [preSelected, setPreSelected] = useState([]);
  var selectDefault = [];
  var selectProdIds = [];
  useEffect(() => {
    if (cook) {
      cook.recommended_products?.map((product) => {
        selectDefault = [
          ...selectDefault,
          {
            value: product.id,
            label: product.product_translations[1].nameProduct,
          },
        ];
        selectProdIds = [...selectProdIds, product.id];
      });
      setPreSelected(selectDefault);
      setValues({ ...values, recommandedProducts: selectProdIds });
    }
  }, [cook]);

  const multiSelectOnChange = (selectedOptions) => {
    var selected = [];
    selectedOptions.map((option) => {
      selected = [...selected, option.value];
    });
    setValues({ ...values, recommandedProducts: selected });
  };

  var optionsArray = [];
  const [options, setOptions] = useState([]);
  useEffect(() => {
    products?.map((product) => {
      optionsArray = [
        ...optionsArray,
        {
          value: product.id,
          label: product.product_translations[1].nameProduct,
        },
      ];
    });
    setOptions(optionsArray);
  }, [products]);

  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <form onSubmit={updateData}>
        <div className="m-auto mt-6 flex">
          <div>
            <SideBar />
          </div>
          <div className="w-full ml-6 text-[#033362] font-semibold">
            <div className="flex justify-between items-center w-full">
              <Breadcrumbs
                breads={[
                  {
                    title: "Cooks Categories",
                    link: "/cooks-categories",
                  },
                  {
                    title: `Cooks Category Details (${selectedCooksCategory.id})`,
                    link: `/cooks-category-details?tab=1`,
                  },
                  {
                    title: `Cook Details (${cookId})`,
                    link: `/cook-edit?id=${cookId}`,
                  },
                ]}
              />
              <div className="flex items-center h-8 w-[30%]">
                <UserIcon />
              </div>
            </div>
            <div className="bg-[white] h-[694px] mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
              <div className="ml-[6.5%] scrollbar-thin h-full scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                <div className="">
                  <div className="flex mt-6 flex-wrap">
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="name-en"
                      >
                        Name - EN
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Enter cook name in english"
                        onChange={changeHandler}
                        value={values.nameEn}
                        name="nameEn"
                        type="text"
                        id="name-en"
                      />
                    </div>
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="name-ar"
                      >
                        Name - AR
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Enter cook name in arabic"
                        onChange={changeHandler}
                        value={values.nameAr}
                        name="nameAr"
                        type="text"
                        id="name-ar"
                      />
                    </div>
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="ingredients_en"
                      >
                        Engredients - En
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Enter the cook engredients in english"
                        onChange={changeHandler}
                        value={values.ingredients_en}
                        name="ingredients_en"
                        type="text"
                        id="ingredients_en"
                      />
                    </div>
                    <div className="w-[40%] mr-[20px] my-[10px]">
                      <label
                        className="text-[14px] font-[500]"
                        htmlFor="video-url"
                      >
                        Engredients - Ar
                        <span className="text-[red] font-[700]">*</span>
                      </label>
                      <input
                        required
                        className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                        placeholder="Enter the cook engredients in arabic"
                        onChange={changeHandler}
                        value={values.standards}
                        name="standards"
                        type="text"
                        id="standards"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-[30px] w-[87%]">
                    {descEn !== false && (
                      <RichTextEditor
                        name="Description-EN"
                        initialValue={descEn}
                        getValue={setDescEn}
                      />
                    )}
                    {descAr !== false && (
                      <RichTextEditor
                        name="Description-AR"
                        initialValue={descAr}
                        getValue={setDescAr}
                      />
                    )}
                  </div>
                  <div className="flex">
                    <div className="mt-6 mr-[20px] w-[40%]">
                      <p className="text-[#000] text-[14px] font-[400]">
                        Select an image for background ( JPEG or PNG ):
                        <span className="text-[red] font-[700]">*</span>
                      </p>
                      <div className=" w-[130px] h-[30px] mt-[10px]">
                        <FileUpload
                          required={
                            backgroundImage?.length === 0 &&
                            databaseBackgroundImage?.length === 0
                          }
                          setDatabaseFile={setDatabaseBackgroundImage}
                          files={backgroundImage}
                          setFiles={setBackgroundImage}
                          setFileData={setBackgroundImageData}
                          removeFile={removeBackgroundImage}
                        />
                      </div>
                      <div>
                        <FileItem
                          removeDatabaseFile={removeDatabaseBackgroundImage}
                          databaseFile={databaseBackgroundImage}
                          fileData={backgroundImageData}
                          file={backgroundImage?.[0]}
                          removeFile={removeBackgroundImage}
                          index="0"
                        />
                      </div>
                    </div>
                    <div className="mt-6 w-[40%]">
                      <p className="text-[#000] text-[14px] font-[400]">
                        Recommended Products{" "}
                        <span className="text-[red] font-[700]">*</span>
                      </p>
                      <div className="w-[500px]">
                        {preSelected.length !== 0 ? (
                          <Select
                            isMulti
                            options={options}
                            defaultValue={preSelected?.map((ele) => ele)}
                            onChange={multiSelectOnChange}
                          />
                        ) : null}
                        {preSelected.length === 0 ? (
                          <Select
                            isMulti
                            options={options}
                            onChange={multiSelectOnChange}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="my-12">
                    <h1 className="text-[#000] font-[700] text-[14px]">
                      Photo Slider
                    </h1>
                    <p className="text-[#000] text-[14px] font-[400]">
                      Select a images of Product ( JPEG or PNG ):
                      <span className="text-[red] font-[700]">*</span>
                    </p>
                    <div className=" w-[130px] h-[30px] mt-[10px]">
                      <FileMultiUpload
                        required={
                          sliderFiles?.length === 0 &&
                          databaseSlider?.length === 0
                        }
                        files={sliderFiles}
                        filesData={sliderFilesData}
                        setFilesData={setSliderFilesData}
                        setFiles={setSliderFiles}
                        removeFile={removeSliderFile}
                      />
                    </div>
                    <div>
                      <FileList
                        removeDatabaseFile={removeDatabaseSlider}
                        databaseFiles={databaseSlider}
                        files={sliderFiles}
                        filesData={sliderFilesData}
                        removeFile={removeSliderFile}
                      />
                    </div>
                    <div className="text-[#000] mt-[20px] text-[14px] font-[400]">
                      Or Use Video Link(Youtube, Vimo...)
                    </div>
                    <input
                      className="h-[30px] mt-[5px] rounded-md w-[80%] border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
                      placeholder="Copy URL for the Video Here"
                      onChange={changeHandler}
                      value={values.video_url_instead_slider}
                      name="video_url_instead_slider"
                      type="url"
                    />
                  </div>
                  {error ? (
                    <p className="text-[14px] mt-2 text-[red]">
                      <FontAwesomeIcon icon={faCircleExclamation} /> {error}
                    </p>
                  ) : null}
                  {loading ? <Loading /> : null}
                  {showError ? (
                    <NetworkError setShowModal={setShowError} />
                  ) : null}
                  <div className="flex mb-2 float-right mr-12 mb-4 pb-[20px] ">
                    <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
                      Cancel
                    </div>
                    <input
                      type="submit"
                      className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
                      value="Save"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default CookEdit;
