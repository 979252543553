import axios from "axios";
import React, { useEffect, useState } from "react";
import { selectUser } from "../../redux/slices/userSlice";
import { useSelector } from "react-redux";
import Loading from "../Loading";
import NetworkError from "../Modals/NetworkError";
import Swal from "sweetalert2";
function Currencies() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["Admin"].includes(adminRole);
  const [values, setValues] = useState({});

  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [sypToUsd, setSypToUsd] = useState(null);
  const [sypToEur, setSypToEur] = useState(null);
  const [sypToAed, setSypToAed] = useState(null);

  const fetchCurrencies = async () => {
    await axios
      .get("https://api.camp-coding.site" + "/currencies/findall")
      .then((res) => {
        setSypToUsd(
          res.data.filter((currency) => {
            return currency.to_currency === "USD";
          })[0]
        );
        setSypToEur(
          res.data.filter((currency) => {
            return currency.to_currency === "EUR";
          })[0]
        );
        setSypToAed(
          res.data.filter((currency) => {
            return currency.to_currency === "AED";
          })[0]
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const saveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post("https://api.camp-coding.site" + "/currencies/update", {
        to_currency: "AED",
        rate: sypToAed.rate.toString(),
      })
      .then((res) => {
        axios
          .post("https://api.camp-coding.site" + "/currencies/update", {
            to_currency: "EUR",
            rate: sypToEur.rate.toString(),
          })
          .then((res) => {
            axios
              .post("https://api.camp-coding.site" + "/currencies/update", {
                to_currency: "USD",
                rate: sypToUsd.rate.toString(),
              })
              .then((res) => {
                setLoading(false);
                Swal.fire({
                  icon: "success",
                  title: "Your changes had been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  return (
    <form onSubmit={saveChanges}>
      <div className="font-[500] text-[17px]">
        <div className="flex mb-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">1</div>
          </div>
          <div className="ml-[6%] mt-6">
            <span>{sypToAed?.from_currency}</span> <span>to</span>{" "}
            <span> {sypToAed?.to_currency}</span>
            {": "}
            <input
              step="0.00001"
              required
              disabled={disabled}
              onChange={(e) => {
                setSypToAed({ ...sypToAed, rate: e.target.value });
              }}
              value={sypToAed?.rate}
              placeholder=""
              className="w-[300px] font-[400] text-[15px] h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              type="number"
              min="0"
            />
          </div>
        </div>
        <div className="flex mb-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">2</div>
          </div>
          <div className="ml-[6%] mt-6">
            <span>{sypToUsd?.from_currency}</span> <span>to</span>{" "}
            <span> {sypToUsd?.to_currency}</span>
            {": "}
            <input
              step="0.00001"
              required
              disabled={disabled}
              onChange={(e) => {
                setSypToUsd({ ...sypToUsd, rate: e.target.value });
              }}
              value={sypToUsd?.rate}
              placeholder=""
              className="w-[300px] font-[400] text-[15px] h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              type="number"
              min="0"
            />
          </div>
        </div>
        <div className="flex mb-[40px]">
          <div>
            <div className="text-[#0388CC] font-bold text-[14px]">3</div>
          </div>
          <div className="ml-[6%] mt-6">
            <span>{sypToEur?.from_currency}</span> <span>to</span>{" "}
            <span> {sypToEur?.to_currency}</span>
            {": "}
            <input
              step="0.00001"
              required
              disabled={disabled}
              onChange={(e) => {
                setSypToEur({ ...sypToEur, rate: e.target.value });
              }}
              value={sypToEur?.rate}
              placeholder=""
              className="w-[300px] font-[400] text-[15px] h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              type="number"
              min="0"
            />
          </div>
        </div>
        {loading && <Loading />}
        {showError ? <NetworkError setShowModal={setShowError} /> : null}
        <div className="absolute flex pb-[10px] right-[80px] bottom-[10px]">
          <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
            Cancel
          </div>
          <input
            type="submit"
            className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
}

export default Currencies;
