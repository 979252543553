import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import OrderInfo from "../components/orders/OrderInfo";
import Title from "../components/orders/Title";
import CartContent from "../components/leftside/CartContent";
import PriceRow from "../components/leftside/PriceRow";
import { faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faBell, faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Breadcrumbs from "../components/Breadcrumbs";
import UserIcon from "../components/UserIcon";
import Footer from "../components/Footer"
function OrderDetails() {
  const [order, setOrder] = useState({});

  let { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let orderId = queryParams.get("id");

  const fetchOrder = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/orders/findone/${orderId}`
    );
    setOrder(response.data);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  return (
    <div className="w-[96%] text-[17px] m-auto mt-6">
      <div className="m-auto mt-6 flex">
        <div>
          <SideBar />
        </div>
        <div className="w-full ml-6 text-[#033362] font-semibold">
          <div className="flex justify-between items-center w-full">
            <Breadcrumbs
              breads={[
                {
                  title: "Orders",
                  link: "/orders",
                },
                {
                  title: `Order Details (${orderId})`,
                  link: "/order-details?tab=1",
                },
              ]}
            />
            <div className="flex items-center h-8 w-[30%]">
              <UserIcon />
            </div>
          </div>
          <div className="bg-[white] h-[694px] mt-2 rounded-xl shadow-[0px_0px_16px_rgb(210,215,211)]">
            <div className="scrollbar-thin h-full scrollbar-thumb-[#0388CC] scrollbar-track-blue-300 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              <div className="flex w-[100%] mt-10">
                <div className=" flex-[60%] w-full">
                  <div className="  mb-20 flex flex-col ">
                    {/* <Breadcrumb visible={true} /> */}
                    <div>
                      <div className="mx-[5%] mt-2">
                        <Title text="Order Details" />
                      </div>
                      <OrderInfo title="Order ID" value={order.id} />
                      <OrderInfo
                        title="Order Date"
                        value={order.CreatedAt?.slice(0, 10)}
                      />
                      <OrderInfo
                        title="Order Status"
                        value={order.order_status}
                        color="green-500"
                        font="bold"
                      />
                      <OrderInfo
                        title="Payment Method"
                        value={order.payment_way}
                      />
                    </div>{" "}
                    <div>
                      <div className="mx-[5%] mt-2">
                        <Title text="Customer Details" />
                      </div>
                      <OrderInfo
                        title="Request Recipient's Name"
                        value={order.receiver_name}
                      />
                      <OrderInfo
                        title="Recipient's Mobile Number"
                        value={order.receiver_phone}
                      />
                      <OrderInfo
                        title="Shipping Address"
                        value={order.shipping_add}
                        color="green-500"
                        font="bold"
                      />
                      <OrderInfo
                        title="Customer Comment"
                        value={order.customer_comment}
                      />
                    </div>
                    <div>
                      <div className="mx-[5%] mt-2">
                        <Title text="Option" />
                      </div>
                      <OrderInfo
                        title="Reward Points"
                        value={order.reward_points}
                      />
                      <OrderInfo
                        title="Used Coupon"
                        value={order.used_coupon}
                        color="green-500"
                        font="bold"
                      />
                    </div>
                  </div>
                </div>
                <div className=" flex flex-col  flex-[40%]">
                  <CartContent orderItems={order.order_items} />
                  <div className="bg-green-50 rounded-xl my-1 grid grid-cols-1  divide-y w-[76%] mx-[12%]  ">
                    <PriceRow title="Subtototal" price={order.subtotal} />
                    <PriceRow
                      title="Shipping Cost"
                      price={order.shipping_cost}
                    />
                    <PriceRow title="Discount" price={order.discount_value} />
                    <PriceRow title="VAT" price={order.vat + "%"} />
                  </div>
                  <button className="w-[76%] px-10 bg-green-600 flex justify-between items-center rounded-md  mx-[12%] h-10 ">
                    <p className="text-[18px] text-white font-medium opacity-90">
                      Total{" "}
                      <span className="text-sm font-normal">
                        {" "}
                        (Include Tax)
                      </span>
                    </p>
                    <p className="text-[18px] text-white font-bold  opacity-90">
                      {order.total}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default OrderDetails;
