import React, { useState, useEffect } from "react";
import FileUpload from "../FileUploader/FileUpload";
import FileItem from "../FileUploader/FileItem";
import RichTextEditor from "../RichTextEditor";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCategory } from "../../redux/slices/itemSlice";
import { useSelector } from "react-redux";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Loading from "../Loading";
import NetworkError from "../../components/Modals/NetworkError";
import Swal from "sweetalert2";

function EditCategory() {
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Database files
  const [databaseImage, setDatabaseImage] = useState([]);
  const removeDatabaseImage = (index) => {
    setDatabaseImage(databaseImage.filter((file, i) => i !== index));
  };

  const [databaseIcon, setDatabaseIcon] = useState([]);
  const removeDatabaseIcon = (index) => {
    setDatabaseIcon(databaseIcon.filter((file, i) => i !== index));
  };
  //Category Image
  const [categoryImage, setCategoryImage] = useState([]);
  const [categoryImageData, setCategoryImageData] = useState([]);
  const removeCategoryImage = (filename, index) => {
    setCategoryImage(null);
    setCategoryImageData(null);
  };

  //Category Icon
  const [categoryIcon, setCategoryIcon] = useState([]);
  const [categoryIconData, setCategoryIconData] = useState([]);
  const removeCategoryIcon = (filename, index) => {
    setCategoryIcon(null);
    setCategoryIconData(null);
  };

  const [values, setValues] = useState({});
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [category, setCategory] = useState({});
  const [categories, setCategories] = useState([]);

  const fetchCategory = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/categories/${selectedCategory}`
    );
    setValues({
      nameEn: response.data.category_translations[0].name,
      nameAr: response.data.category_translations[1].name,
      parent: response.data.parent_id,
    });
    setDatabaseImage([response.data.image_url]);
    setDatabaseIcon([response.data.icon_url]);
  };

  const fetchCategories = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/categories/findall`
    );
    setCategories(response.data);
  };

  useEffect(() => {
    fetchCategory();
    fetchCategories();
  }, []);

  const [imageUrl, setImageUrl] = useState([]);
  const [iconUrl, setIconUrl] = useState([]);

  useEffect(() => {
    if (imageUrl.length !== 0) {
      uploadIcon();
    }
  }, [imageUrl]);

  useEffect(() => {
    if (iconUrl.length !== 0) {
      updateAll();
    }
  }, [iconUrl]);

  const selectedCategory = useSelector(selectCategory);

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("file", categoryImage[0], categoryImage[0].name);
    await axios
      .post(
        `${"https://api.camp-coding.site"}/categories/upload/category-image`,
        formData
      )
      .then((imgResponse) => {
        setImageUrl([imgResponse.data.imagePath]);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const uploadIcon = async () => {
    if (categoryIcon.length !== 0) {
      setError(null);
      const formData = new FormData();
      formData.append("file", categoryIcon[0], categoryIcon[0].name);
      await axios
        .post(
          `${"https://api.camp-coding.site"}/categories/upload/category-image`,
          formData
        )
        .then((imgResponse) => {
          setIconUrl([imgResponse.data.imagePath]);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setIconUrl([...databaseIcon]);
    }
  };

  const updateAll = async () => {
    axios
      .post(
        `${"https://api.camp-coding.site"}/categories/update/${selectedCategory}`,
        {
          image_url: imageUrl[0],
          icon_url: iconUrl[0],
          name_en: values.nameEn,
          name_ar: values.nameAr,
          parent_id: parseInt(values.parent),
        }
      )
      .then(function (response) {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your changes had been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(function (error) {
        if (error.code === "ERR_NETWORK") {
          setLoading(false);
          setShowError(true);
          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      });
  };

  const updateCategory = (e) => {
    e.preventDefault();
    if (
      (categoryImage.length !== 0 || databaseImage.length !== 0) &&
      (categoryIcon.length !== 0 || databaseIcon.length !== 0) &&
      values.nameAr !== "" &&
      values.nameEn !== ""
    ) {
      setError(null);
      setLoading(true);
      if (categoryImage.length !== 0) {
        uploadImage();
      } else {
        setImageUrl([...databaseImage]);
      }
    } else {
      setError("Please fill all the required fields.");
    }
  };

  return (
    <form onSubmit={updateCategory}>
      <div>
        <div className="ml-[6.5%]">
          <div className="w-[90%] mr-[120px] my-[10px]">
            <label className="text-[14px] font-[500]" htmlFor="name-en">
              Name - En <span className="text-[red] font-[700]">*</span>
            </label>
            <input
              required
              className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              placeholder="Enter category name in english"
              onChange={changeHandler}
              value={values.nameEn}
              name="nameEn"
              type="text"
              id="name-en"
            />
          </div>
          <div className="w-[90%] mr-[120px] my-[10px]">
            <label className="text-[14px] font-[500]" htmlFor="name-ar">
              Name - Ar <span className="text-[red] font-[700]">*</span>
            </label>
            <input
              required
              className="w-full h-[30px] rounded border-[#c4c8cf] placeholder:text-[center] placeholder:text-[12px] placeholder:font-[400]"
              placeholder="Enter category name in arabic"
              value={values.nameAr}
              onChange={changeHandler}
              name="nameAr"
              type="text"
              id="name-ar"
            />
          </div>
          <div className="">
            <p className="text-[14px] font-[500]">Parents</p>
            <select
              value={values.parent}
              name="parent"
              onChange={changeHandler}
              className="w-[50%] px-1 rounded font-[400] text-[14px] text-[#000]"
            >
              <option value={null}>No parent</option>
              {categories?.map((optionCategory) => (
                <option
                  selected={optionCategory.id === category.parent_id}
                  value={optionCategory.id}
                >
                  {optionCategory.category_translations[1].name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="ml-[6.5%] mt-[40px]">
          <div>
            <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
              <span className="font-[500]">Image</span> : Thumbnails will be
              generated from detailed image automatically, but you can also{" "}
              <span className="text-[#2685CC]">upload them manually</span>
            </p>
            <p className="text-[#000] text-[14px] font-[400]">
              Select a images of Product ( JPEG or PNG ):
              <span className="text-[red] font-[700]">*</span>
            </p>
            <div className=" w-[130px] h-[30px] mt-[5px]">
              <FileUpload
                required={
                  databaseImage?.length === 0 && categoryImage?.length === 0
                }
                setDatabaseFile={setDatabaseImage}
                files={categoryImage}
                setFiles={setCategoryImage}
                setFileData={setCategoryImageData}
                removeFile={removeCategoryImage}
              />
            </div>
            <FileItem
              removeDatabaseFile={removeDatabaseImage}
              databaseFile={databaseImage}
              fileData={categoryImageData}
              file={categoryImage?.[0]}
              removeFile={removeCategoryImage}
              index="0"
            />
          </div>

          <div className="mt-10">
            <p className="text-[#000] mt-[20px] text-[14px] font-[400]">
              <span className="font-[500]">Icon</span> : Thumbnails will be
              generated from detailed image automatically, but you can also{" "}
              <span className="text-[#2685CC]">upload them manually</span>
            </p>
            <p className="text-[#000] text-[14px] font-[400]">
              Select a images of Product ( JPEG or PNG ):
              <span className="text-[red] font-[700]">*</span>
            </p>
            <div className=" w-[130px] h-[30px] mt-[5px]">
              <FileUpload
                required={
                  databaseIcon?.length === 0 && categoryIcon?.length === 0
                }
                setDatabaseFile={setDatabaseIcon}
                files={categoryIcon}
                setFiles={setCategoryIcon}
                removeFile={removeCategoryIcon}
                setFileData={setCategoryIconData}
              />
            </div>
            <FileItem
              removeDatabaseFile={removeDatabaseIcon}
              databaseFile={databaseIcon}
              fileData={categoryIconData}
              file={categoryIcon?.[0]}
              removeFile={removeCategoryIcon}
              index="0"
            />
          </div>
          {error ? (
            <p className="text-[14px] mt-2 text-[red]">
              <FontAwesomeIcon icon={faCircleExclamation} /> {error}
            </p>
          ) : null}
        </div>
        {loading ? <Loading /> : null}
        {showError ? <NetworkError setShowModal={setShowError} /> : null}
        <div className="absolute flex pb-[10px] right-[80px] bottom-[0px]">
          <div className="cursor-pointer rounded-full text-[13px] mr-2 px-6 py-1 text-[#0388CC] bg-[#E9F6FD]">
            Cancel
          </div>
          <input
            type="submit"
            className="cursor-pointer rounded-full text-[13px] px-6 py-1 text-[#fff] bg-[#0388CC]"
            value="Save"
          />
        </div>
      </div>
    </form>
  );
}

export default EditCategory;
