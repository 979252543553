import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import columns from "../../global/columns/activityColumns";
import {
  faCirclePlus,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NewActivity from "../Modals/NewActivity";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice";
import DeleteWarning from "../Modals/DeleteWarning";
import { selectSearch } from "../../redux/slices/itemSlice";
import { useDispatch } from "react-redux";
import { setSearch } from "../../redux/slices/itemSlice";
function Activities() {
  const dispatch =  useDispatch();
  const searchInput = useSelector(selectSearch);
  const currentUser = useSelector(selectUser);
  const adminRole = currentUser?.user?.[1];
  const disabled = !["DataEntry", "Admin"].includes(adminRole);

  const gridStyle = {
    minHeight: 590,
    border: "none",
    fontSize: "13px",
    fontWeight: "200",
  };
  const showCellBorders = false;
  const showZebraRows = false;
  const [showModal, setShowModal] = React.useState(false);

  const [activities, setActivities] = useState([]);
  var data = [];
  const fetchActivities = async () => {
    const response = await axios.get(
      `${"https://api.camp-coding.site"}/activities/findall`
    );
    const data = response.data;
    if (data.length !== activities.length) {
      setActivities(data);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [activities]);

  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    setFilteredData(activities);
  }, [activities]);

  useEffect(() => {
    dispatch(setSearch(""));
  }, []);

  useEffect(() => {
    if (searchInput !== "") {
      setFilteredData(
        activities.filter((activity) => {
          return activity.activity_translations[0]?.title.includes(searchInput);
        })
      );
    } else {
      setFilteredData(activities);
    }
  }, [searchInput]);

  for (let i = 0; i < filteredData?.length; i++) {
    const activity = filteredData[i];
    data = [
      ...data,
      {
        title: activity.activity_translations[0]?.title,
        description: activity.activity_translations[0]?.descrition,
        category: activity.category==="national" ? "معارض سورية" : "معارض عالمية",
        lastUpdated: activity.updatedAt.slice(0, 10),
        view: (
          <Link
            to={!disabled ? `/activity-edit?id=${activity.id}` : "/news?tab=2"}
          >
            <div className="text-[14px] bg-[#0388CC] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center">
              <FontAwesomeIcon icon={faEye} />
            </div>
          </Link>
        ),
        delete: (
          <div
            onClick={() => {
              if (!disabled) {
                setItemToDelete({ id: filteredData[i].id, index: i });
                setShowWarningModal(true);
              }
            }}
            className="bg-[red] cursor-pointer rounded w-fit px-3 py-[2px] text-[white] text-center"
          >
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ),
      },
    ];
  }

  return (
    <div className="flex flex-col justify-between">
      <div className=" p-3 w-full">
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          showCellBorders={showCellBorders}
          showZebraRows={showZebraRows}
          style={gridStyle}
          dataSource={data}
        />
      </div>
      {showModal ? (
        <NewActivity
          activities={activities}
          setActivities={setActivities}
          setShowModal={setShowModal}
        />
      ) : null}
      {showWarningModal ? (
        <DeleteWarning
          url="/activities/delete/"
          setShowModal={setShowWarningModal}
          itemToDelete={itemToDelete}
          items={activities}
          setItems={setActivities}
        />
      ) : null}
      <div
        onClick={() => !disabled && setShowModal(true)}
        className="cursor-pointer flex items-center text-[#0388CC] h-[30px] mb-[0px] ml-[20px] text-[13px]"
      >
        <div className="mr-2">Add new activity </div>
        <div className="text-[20px]">
          <FontAwesomeIcon icon={faCirclePlus} />
        </div>
      </div>
    </div>
  );
}

export default Activities;
